/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '../..';
import Hooks from '../../../hooks';
import {
  BasketItemType,
  IAttractionsBasketItem,
  IBasketItem,
} from '../../../modules/basket/models';
import {
  IOption,
  IProduct,
  IProductAdditionalSettings,
  IProductComponents,
} from '../../../modules/common/models/productModels';
import './Attractions.scss';
import { addToBasket } from '../../../modules/basket/operations';
import { IAttractions } from '../../../modules/attractions/models';
import Icons from '../../../assets/icons';
import InformationPopup from '../../InformationPopup/InformationPopup';
import getClientsStyle from '../../../utils/getClientsStyle';
import { getAttractionsDate } from '../../../modules/attractions/selectors';
import formatDate from '../../../utils/formatDate';
interface IProps {
  attraction: IAttractions;
}

const Attractions = (props: IProps) => {
  const {
    attraction: {
      name,
      description,
      defaultPrice,
      consumerCategories,
      coverImageUrl,
      // expirationDate,
      icon,
      productComponents,
      availabilityCount,
      unlimited,
    },
  } = props;

  const { t } = useTranslation('components');
  const styleForClient = getClientsStyle();
  const [isVisibleInfoPopup, setIsVisibleInfoPopup] = useState(false);
  const [isVisibleCategoryPopup, setIsVisibleCategoryPopup] = useState(false);
  const [idCategory, setIdCategory] = useState('');
  const [showSmallPopup, setShowSmallPopup] = useState(false);
  const [hours, setHours] = useState<IProductComponents[]>();
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const quantitiesSum = Object.values(quantities).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const currentCurrency = consumerCategories[0]?.price?.currency;
  // const dateFormat = Hooks.useDateFormat();
  const { getPriceDisplay } = Hooks.usePriceDisplay(currentCurrency);

  useEffect(() => {
    const processedProductComponents = productComponents.map((product) => ({
      ...product,
      productAdditionalSettings: product.productAdditionalSettings.map(
        // eslint-disable-next-line max-nested-callbacks
        (setting) => ({
          ...setting,
          value: '',
        })
      ),
    }));
    setHours(processedProductComponents);
  }, [productComponents]);

  const totalPrice = () => {
    let total = 0;

    for (const categoryId in quantities) {
      const quantity = quantities[categoryId];
      const pickedCategory = consumerCategories.find(
        (cat) => cat.id === categoryId
      );
      if (pickedCategory) {
        total += +(pickedCategory?.price?.grossAmount || 0) * quantity;
      }
    }
    return total;
  };

  const dispatch = Hooks.useAppDispatch();
  const date = Hooks.useAppSelector((s) => getAttractionsDate(s.attractions));

  const handleAddToBasket = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const itemsToAdd: IBasketItem[] = [];

    for (const categoryId in quantities) {
      const quantity = quantities[categoryId];
      if (quantity > 0) {
        const pickedCategory = consumerCategories.find(
          (cat) => cat.id === categoryId
        );
        if (pickedCategory) {
          const item: IBasketItem = {
            itemType: BasketItemType.Attractions,
            price: pickedCategory?.price?.grossAmount,
            quantity,
            // date: expirationDate, //do zmiany na date
            date, //do zmiany na date,
            productComponents: hours,
            currency: pickedCategory?.price?.currency,
            item: props.attraction as IProduct,
            consumerCategoryId: pickedCategory?.id,
            pickupMethod: 'onSite',
            priceBeforeRecalculation: 0,
            priceRecalculated: false,
            name: name,
          } as IAttractionsBasketItem;
          itemsToAdd.push(item);
        }
      }
    }
    dispatch(addToBasket(itemsToAdd));
    e.currentTarget.reset();
    setQuantities({});

    setShowSmallPopup(true);
  };

  const handleQuantityChange = (categoryId: string, quantity: number) => {
    if (
      !unlimited &&
      availabilityCount &&
      quantitiesSum + 1 >= availabilityCount &&
      quantity > quantities[categoryId]
    ) {
      return;
    }
    setQuantities((prevState) => ({
      ...prevState,
      [categoryId]: quantity,
    }));
  };

  const iconCart = (name: string) => {
    switch (name) {
      case 'goldStar':
        return <Icons.StartGold />;
      case 'silverStar':
        return <Icons.StartSilver />;
      case 'brownStar':
        return <Icons.StartBrown />;
      default:
        return '';
    }
  };

  const minPriceBeforeRecalculation = Number(
    consumerCategories?.find((typ) => typ.name == 'normalny')
      ?.priceBeforeRecalculation?.grossAmount
  );

  const conversionOfDiscountIntoCash = Math.round(
    Number(minPriceBeforeRecalculation) - Number(defaultPrice)
  );

  const handleToggleInfoPopup = () => {
    setIsVisibleInfoPopup(!isVisibleInfoPopup);
  };

  const handleToggleCategoryPopup = () => {
    setIsVisibleCategoryPopup(!isVisibleCategoryPopup);
  };

  const handleCategoryInfoClick = (categoryId: string) => {
    setIdCategory(categoryId);
    handleToggleCategoryPopup();
  };

  const handleCloseInfoPopup = () => {
    setIsVisibleInfoPopup(false);
  };

  const handleCloseCategoryPopup = () => {
    setIsVisibleCategoryPopup(false);
  };

  const productDropdowns = productComponents.map(
    (product: IProductComponents) => {
      const dropdownOptions = product.productAdditionalSettings[0]?.options.map(
        (option: IOption) => ({
          label: option.name,
          value: option.name,
        })
      );

      return {
        productId: product.id,
        dropdownOptions: dropdownOptions,
      };
    }
  );

  const handleCategoryChange = (
    value: string,
    productId: number,
    settingName: string
  ) => {
    const updatedProductComponents = hours?.map((product) => {
      if (product.id === productId) {
        const updatedProductAdditionalSettings =
          product.productAdditionalSettings.map((setting) => {
            if (product.name === settingName) {
              return {
                ...setting,
                value: value,
              };
            }
            return { ...setting };
          });
        return {
          ...product,
          productAdditionalSettings: updatedProductAdditionalSettings,
        };
      }
      return { ...product };
    });
    setHours(updatedProductComponents);
  };

  return (
    <>
      {isVisibleInfoPopup && (
        <InformationPopup
          attractionName={props.attraction.name}
          attractionFullDescription={props.attraction?.fullDescription}
          isVisibleInfoPopup={isVisibleInfoPopup}
          handleClose={handleCloseInfoPopup}
        />
      )}
      {isVisibleCategoryPopup && (
        <InformationPopup
          attractionCategoryDescription={
            props.attraction?.consumerCategories.find(
              (x) => x.id === idCategory
            )?.description
          }
          attractionNameCategory={
            props.attraction?.consumerCategories.find(
              (x) => x.id === idCategory
            )?.name
          }
          isVisibleInfoPopup={isVisibleCategoryPopup}
          handleClose={handleCloseCategoryPopup}
        />
      )}
      {unlimited === true ||
      (unlimited === false && availabilityCount && availabilityCount > 0) ? (
        <form
          onSubmit={handleAddToBasket}
          className="berg-components-attractions-wrapper"
        >
          <div className="berg-components-attractions__promo">
            {iconCart(icon)}
          </div>
          <div className="berg-components-attractions__picture flex flex-col justify-between">
            <img src={coverImageUrl} alt={t('attractions.img_alt')} />

            <div className="flex flex-col gap-4 bg-white rounded-2xl sm:pb-4">
              {/* <div
                className="berg-components-attractions__box"
                data-client-style={styleForClient}
              >
                {t('attractions.box')}
              </div> */}
              <div className="berg-components-attractions__title flex flex-row gap-1 justify-center items-center">
                <div className="name">{name}</div>
                <button onClick={handleToggleInfoPopup} type="button">
                  <Icons.InfoBrama />
                </button>
              </div>
              <div
                className="berg-components-attractions__sum"
                aria-disabled={!quantities}
              >
                <div className="berg-components-attractions__sum--second">
                  <p>
                    {t('attractions.from')} {getPriceDisplay(defaultPrice || 0)}
                  </p>
                  {consumerCategories?.find((typ) => typ.name == 'normalny')
                    ?.priceRecalculated === true && (
                    <div>
                      {t('attractions.cheap')}{' '}
                      {getPriceDisplay(conversionOfDiscountIntoCash)}
                    </div>
                  )}
                </div>
                {consumerCategories?.find((typ) => typ.name == 'normalny')
                  ?.priceRecalculated === true && (
                  <div className="berg-components-attractions__sum--first">
                    {t('attractions.price_before_recalculated')}{' '}
                    <p>{getPriceDisplay(minPriceBeforeRecalculation || 0)}</p>
                  </div>
                )}

                <div className="berg-components-attractions__sum--third">
                  {t('attractions.price_previous_days')}{' '}
                  {getPriceDisplay(defaultPrice || 0)}
                </div>
                {unlimited === false && (
                  <div className="berg-components-attractions__sum--first text-[14px] mt-[5px]">
                    {t('attractions.number_of_available_packages')}:{' '}
                    {availabilityCount}
                  </div>
                )}
                {unlimited === true && (
                  <div className="berg-components-attractions__sum--first text-[14px] mt-[5px]"></div>
                )}
              </div>
            </div>
          </div>
          <div className="berg-components-attractions__content">
            <div className="berg-components-attractions__choices">
              {consumerCategories.map((category) => (
                <div
                  key={category.id}
                  className="flex flex-row items-center justify-between rectangle"
                >
                  <div className="flex flex-row items-center gap-4">
                    <span>{category.name}</span>

                    {getPriceDisplay(category?.price?.grossAmount)}
                  </div>
                  <div className="flex flex-row gap-2">
                    <button
                      onClick={() => handleCategoryInfoClick(category.id)}
                      type="button"
                    >
                      {category.description && <Icons.InfoBrama />}
                    </button>
                    <Components.NumberCounter
                      counter={quantities[category?.id] || 0}
                      onChange={(count: number) =>
                        handleQuantityChange(category?.id, count)
                      }
                      maxCounter={99}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div
              className="berg-components-attractions__submit flex flex-col  gap-4 justify-center"
              data-client-style={styleForClient}
            >
              <div className="flex flex-row justify-center gap-2 lg:items-center">
                <strong>{t('attractions.total')}</strong>{' '}
                <p>{getPriceDisplay(Number(totalPrice()))}</p>
              </div>
              {quantitiesSum > 0 &&
              ((availabilityCount &&
                !unlimited &&
                quantitiesSum < availabilityCount + 1) ||
                (unlimited === true && availabilityCount === 0)) &&
              hours?.every((product: IProductComponents) =>
                product.productAdditionalSettings.every(
                  (p: IProductAdditionalSettings) =>
                    p && p.value !== undefined && p.value !== null
                      ? p.value.trim() !== ''
                      : true
                )
              ) ? (
                <button>
                  {t('attractions.submit', { count: quantitiesSum })}
                </button>
              ) : (
                <Components.Tooltip
                  content={t('attractions.validator_for_submit')}
                >
                  <button disabled>{t('attractions.submit_zero')}</button>
                </Components.Tooltip>
              )}
            </div>

            <div className="berg-components-attractions__info">
              {/* <div className="flex flex-col gap-4 berg-components-attractions__rules">
              <div className="flex flex-row gap-4 items-center">
                <Icons.Ticket />
                <p>{t('attractions.rules')}</p>
              </div>
              <div className="flex flex-row gap-4 items-center">
                <Icons.CalendarToday />
                <p>
                  {t('attractions.ticket_value')} {dateFormat(expirationDate)}
                </p>
              </div>
            </div> */}
              <div className="text-start flex flex-col gap-3 berg-components-attractions__desc">
                <div className="flex sm:flex-row flex-col gap-4 items-center">
                  <span> {t('attractions.package')}</span>
                  <button onClick={handleToggleInfoPopup} type="button">
                    {t('attractions.full_desc')}
                  </button>
                </div>
                {description}
              </div>
              <div className="flex flex-col items-start gap-4 berg-components-attractions__hour">
                {productComponents &&
                  productComponents.map(
                    (product: IProductComponents, productIndex: number) => (
                      <div
                        key={product.id}
                        className="flex flex-row items-center gap-4"
                        style={{
                          zIndex: productComponents?.length - productIndex,
                        }}
                      >
                        <div className="flex flex-col gap-1 items-start">
                          {product.attractionName}
                          <span>{product.description}</span>
                        </div>
                        {product.productAdditionalSettings.length > 0 && (
                          <div>
                            {product.productAdditionalSettings.length > 0 && (
                              <span>
                                {' '}
                                {t('attractions.select_packages_time')}{' '}
                                {formatDate(date)}
                              </span>
                            )}
                            <Components.Dropdown
                              options={
                                productDropdowns.find(
                                  (dropdown) =>
                                    dropdown.productId === product.id
                                )?.dropdownOptions || []
                              }
                              name={`AttractionsTime_${productIndex}`}
                              onChange={(value: string) =>
                                handleCategoryChange(
                                  value,
                                  product?.id,
                                  product.name
                                )
                              }
                            />
                          </div>
                        )}
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </form>
      ) : (
        <form
          onSubmit={handleAddToBasket}
          className="berg-components-attractions-wrapper"
        >
          <p className="absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-1/2 content-center text-[25px] sm:text-[60px] p-4 uppercase bg-gray-500 text-white rounded-2xl leading-[3rem] sm:leading-[5rem]">
            {t('attractions.package_not_available_change_date')}
          </p>

          <div className="berg-components-attractions__promo  opacity-50">
            {iconCart(icon)}
          </div>
          <div className="berg-components-attractions__picture flex flex-col justify-between opacity-50">
            <img src={coverImageUrl} alt={t('attractions.img_alt')} />

            <div className="flex flex-col gap-4 bg-white rounded-2xl sm:pb-4">
              {/* <div
                className="berg-components-attractions__box"
                data-client-style={styleForClient}
              >
                {t('attractions.box')}
              </div> */}
              <div className="berg-components-attractions__title flex flex-row gap-1 justify-center">
                <div className="name">{name}</div>
                <button onClick={handleToggleInfoPopup} type="button">
                  <Icons.InfoBrama />
                </button>
              </div>
              <div
                className="berg-components-attractions__sum"
                aria-disabled={!quantities}
              >
                <div className="berg-components-attractions__sum--second">
                  <p>
                    {t('attractions.from')} {getPriceDisplay(defaultPrice || 0)}
                  </p>
                  {consumerCategories?.find((typ) => typ.name == 'normalny')
                    ?.priceRecalculated === true && (
                    <div>
                      {t('attractions.cheap')}{' '}
                      {getPriceDisplay(conversionOfDiscountIntoCash)}
                    </div>
                  )}
                </div>
                {consumerCategories?.find((typ) => typ.name == 'normalny')
                  ?.priceRecalculated === true && (
                  <div className="berg-components-attractions__sum--first">
                    {t('attractions.price_before_recalculated')}{' '}
                    <p>{getPriceDisplay(minPriceBeforeRecalculation || 0)}</p>
                  </div>
                )}

                <div className="berg-components-attractions__sum--third">
                  {t('attractions.price_previous_days')}{' '}
                  {getPriceDisplay(defaultPrice || 0)}
                </div>
                {availabilityCount !== 0 && unlimited === false && (
                  <div className="berg-components-attractions__sum--first text-[14px] mt-[5px]">
                    {t('attractions.number_of_available_packages')}:{' '}
                    {availabilityCount}
                  </div>
                )}
                {availabilityCount === 0 && unlimited === false && (
                  <div className="berg-components-attractions__sum--first text-[14px] mt-[5px]">
                    {t('attractions.package_not_available')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="berg-components-attractions__content  opacity-50">
            <div className="berg-components-attractions__choices">
              {consumerCategories.map((category) => (
                <div
                  key={category.id}
                  className="flex flex-row items-center justify-between rectangle"
                >
                  <div className="flex flex-row gap-2">
                    <span>{category.name}</span>
                    <button
                      onClick={() => handleCategoryInfoClick(category.id)}
                      type="button"
                    >
                      {category.description && <Icons.InfoBrama />}
                    </button>
                    {getPriceDisplay(category?.price?.grossAmount)}
                  </div>

                  <Components.NumberCounter
                    counter={quantities[category?.id] || 0}
                    onChange={(count: number) =>
                      handleQuantityChange(category?.id, count)
                    }
                    maxCounter={99}
                    disabled
                  />
                </div>
              ))}
            </div>
            <div
              className="berg-components-attractions__submit flex flex-col  gap-4 justify-center"
              data-client-style={styleForClient}
            >
              <div className="flex flex-row justify-center gap-2 lg:items-center">
                <strong>{t('attractions.total')}</strong>{' '}
                <p>{getPriceDisplay(Number(totalPrice()))}</p>
              </div>
              {Number(totalPrice()) > 0 && quantitiesSum > 0 && hours ? (
                <button>
                  {t('attractions.submit', { count: quantitiesSum })}
                </button>
              ) : (
                <button disabled>{t('attractions.submit_zero')}</button>
              )}
            </div>

            <div className="berg-components-attractions__info">
              <div className="text-start flex flex-col gap-3 berg-components-attractions__desc">
                <span> {t('attractions.package')}</span>
                {description}
              </div>
              <div className="flex flex-col items-start gap-4 berg-components-attractions__hour">
                {productComponents &&
                  productComponents.map(
                    (product: IProductComponents, productIndex: number) => (
                      <div
                        key={product.id}
                        className="flex flex-row items-center gap-4"
                        style={{
                          zIndex: productComponents?.length - productIndex,
                        }}
                      >
                        <div className="flex flex-col gap-1 items-start">
                          {product.attractionName}
                          {product.productAdditionalSettings.length > 0 && (
                            <span>
                              {' '}
                              {t('attractions.select_packages_time')}
                            </span>
                          )}
                        </div>
                        {product.productAdditionalSettings.length > 0 && (
                          <div>
                            <Components.Dropdown
                              name={`AttractionsTime_${productIndex}`}
                              onChange={(value: string) =>
                                handleCategoryChange(
                                  value,
                                  product?.id,
                                  product.name
                                )
                              }
                            />
                          </div>
                        )}
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </form>
      )}

      {showSmallPopup && <Components.SmallPopup />}
    </>
  );
};

export default Attractions;
