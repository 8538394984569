const icons = {
  'cloudy.svg': [119],
  'fog.svg': [248, 143, 260],
  'freezingrain.svg': [377, 374, 350, 314, 311, 284, 281, 185],
  'heavyrain.svg': [308, 305],
  'heavyshower.svg': [356],
  'lightning.svg': [299, 296],
  'lightrain.svg': [176, 293],
  'lightshower.svg': [266, 263, 359],
  'lightsnow.svg': [227, 179],
  'mostlycloudy.svg': [122],
  'partlycloudy.svg': [116],
  'rain.svg': [21, 51, 52, 53, 58, 59, 60, 62, 63],
  'shower.svg': [302, 353],
  'snow.svg': [395, 392, 338, 335, 332, 329, 230],
  'snowrain.svg': [320, 317],
  'snowshower.svg': [368, 323, 326, 371],
  'snowrainshower.svg': [365, 362, 182],
  'sunny.svg': [113],
  'thunderstorm.svg': [389, 386, 200],
  'wind.svg': [18, 19],
};

const unknownIcon = 'unknow.svg';

type TIcon = keyof typeof icons;

const getIcon = (code: number): TIcon | typeof unknownIcon => {
  let result: TIcon | typeof unknownIcon = unknownIcon;

  for (const icon in icons) {
    for (let i = 0; i < icons[icon as TIcon].length; i++) {
      if (code === icons[icon as TIcon][i]) {
        result = icon as TIcon;
      }
    }
  }

  return result;
};

export default getIcon;
